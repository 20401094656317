import { useCallback, useContext } from 'react';

import invariant from 'tiny-invariant';

import { fg } from '@atlaskit/platform-feature-flags';

import {
	SetSideNavDesktopVisibility,
	SetSideNavMobileVisibility,
	SetSideNavVisibilityState,
} from './visibility-context';

type ExpandSideNav = () => void;

/**
 * __useExpandSideNav__
 *
 * Returns a function that will expand the side nav when called.
 *
 * It will switch the appropriate internal desktop or mobile side nav visibility state based on the current screen size.
 *
 * If you need a function to toggle the side nav, use `useToggleSideNav` instead.
 */
export function useExpandSideNav(): ExpandSideNav {
	const setSideNavState = useContext(SetSideNavVisibilityState);

	const expandSideNav = useCallback(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		if (matches) {
			setSideNavState((currentState) => {
				invariant(currentState, 'Side nav state should not be null');

				return {
					mobile: currentState.mobile,
					desktop: 'expanded',
					flyout: 'closed',
				};
			});
		} else {
			setSideNavState((currentState) => {
				invariant(currentState, 'Side nav state should not be null');

				return {
					desktop: currentState.desktop,
					mobile: 'expanded',
					flyout: 'closed',
				};
			});
		}
	}, [setSideNavState]);

	// NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	// NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	// NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
	const expandSideNavOld = useCallback(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		if (matches) {
			setVisibleOnDesktop(true);
		} else {
			setVisibleOnMobile(true);
		}
	}, [setVisibleOnDesktop, setVisibleOnMobile]);

	return fg('platform_nav4_side_nav_flyout_animation') ? expandSideNav : expandSideNavOld;
}
