import {
	createStore,
	createActionsHook,
	createContainer,
	createStateHook,
	type ContainerComponent,
} from 'react-sweet-state';

export type OnboardingTakeoverType = 'v2-land' | 'v2-xflow';

type HTV2StoreContainerProps = {
	takeoverType: OnboardingTakeoverType;
	personalSpaceKey: string;
};

const HTV2Store = createStore({
	initialState: {
		takeoverType: 'v2-land',
		personalSpaceKey: '',
	},
	actions: {
		getIsLandTakeoverType:
			() =>
			({ getState }) =>
				getState().takeoverType === 'v2-land',
		getIsXflowTakeoverType:
			() =>
			({ getState }) =>
				getState().takeoverType === 'v2-xflow',
	},
	name: 'htv2Store',
});

export const useHTV2Actions = createActionsHook(HTV2Store);
export const useHTV2State = createStateHook(HTV2Store);

export const HTV2StoreContainer: ContainerComponent<HTV2StoreContainerProps> = createContainer(
	HTV2Store,
	{
		onInit:
			() =>
			({ setState }, { takeoverType, personalSpaceKey }) => {
				setState({ takeoverType, personalSpaceKey });
			},
	},
);
